<template>
  <div style="margin-bottom: -2rem">
    <translate
      v-if="isTranslateMode"
      :translations="translations"
      :translation-active="translationActive"
      :title="name"
      @setTranslationActive="translationActive = $event"
      @setTranslateMode="setTranslateMode"
      @translate="translateAll"
      @clearTranslation="onClearTranslation"
    >
      <template v-slot:default>
        <b-card>
          <b-form-group :label="$t('Combination Item Name')">
            <validation-observer ref="voNameTranslation">
              <validation-provider
                #default="{ errors }"
                name="combination_item_name"
                rules="required"
              >
                <b-form-input
                  v-model="name"
                  :placeholder="$t('Combination Item Name')"
                  :state="errors.length ? false : null"
                  @blur="onBlurName('voNameTranslation')"
                />
              </validation-provider>
            </validation-observer>
          </b-form-group>
          <template v-if="badges.length">
            <b-form-group
              v-for="(badge, badgeIndex) in badges"
              :key="badge.id"
              :label="badgeIndex === 0 ? $t('Tags') : null"
            >
              <b-form-input
                :value="badge.name"
                disabled
              />
            </b-form-group>
          </template>
          <template v-if="tags.length">
            <b-form-group
              v-for="(tag, tagIndex) in tags"
              :key="tag.id"
              :label="tagIndex === 0 ? $t('Tags') : null"
            >
              <b-form-input
                :value="tag.name"
                disabled
              />
            </b-form-group>
          </template>
          <b-form-group :label="$t('Short Description')">
            <validation-observer ref="voDescriptionTranslation">
              <validation-provider
                #default="{ errors }"
                name="short_description"
                :rules="`max:${textLimitShort}`"
              >
                <b-form-textarea
                  v-model="description"
                  :placeholder="$t('Short Description')"
                  rows="3"
                  no-resize
                  :state="errors.length ? false : null"
                  @blur="onBlurDescription('voDescriptionTranslation')"
                />
              </validation-provider>
            </validation-observer>
          </b-form-group>
          <b-form-group :label="$t('Full Description')">
            <validation-observer ref="voLongDescriptionTranslation">
              <validation-provider
                #default="{ errors }"
                name="full_description"
                :rules="`max:${textLimitFull}`"
              >
                <b-form-textarea
                  v-model="longDescription"
                  :placeholder="$t('Full Description')"
                  rows="3"
                  no-resize
                  :state="errors.length ? false : null"
                  @blur="onBlurLongDescription('voLongDescriptionTranslation')"
                />
              </validation-provider>
            </validation-observer>
          </b-form-group>
        </b-card>
        <b-card-actions
          :title="$t('cardSuggestionsTitle')"
          action-collapse
        >
          <b-form-group
            :label="$t('cardSuggestionsNameLabel')"
            class="mb-0"
          >
            <validation-observer ref="suggestionsName">
              <validation-provider
                #default="{ errors }"
                name="suggestions_name"
                :rules="`max:${textLimitSuggestions}`"
              >
                <b-form-input
                  v-model="suggestionName"
                  :placeholder="$t('cardSuggestionsNamePlaceholder')"
                  :state="errors.length ? false : null"
                  @blur="onBlurSuggestionName"
                />
              </validation-provider>
            </validation-observer>
          </b-form-group>
        </b-card-actions>
      </template>
      <template v-slot:translation="{ translation }">
        <b-card>
          <b-form-group :label="$t('Combination Item Name')">
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="translation.name"
                :disabled="isSubscriptionInactiveOrBasic"
                :placeholder="$t('Combination Item Name')"
                @blur="patchTranslationActive"
              />
              <b-input-group-append
                v-if="isSubscriptionPremium"
                is-text
              >
                <icon-translate
                  v-b-tooltip.hover.top="$t('Auto Translate')"
                  class="size-18"
                  role="button"
                  @click.native="onClickTranslateField('name')"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <item-badges-tags-translations
            ref="item-badges-tags-translations_badges"
            :translation-active="translationActive"
            :value="badges"
            :label="`${$t('Badge')}`"
            :items="badgesFromStore"
            :items-translations="badgesTranslations"
            :set-items-translations="setBadgesTranslations"
            :update-item-translation="updateBadgeTranslation"
          />
          <item-badges-tags-translations
            ref="item-badges-tags-translations_tags"
            :translation-active="translationActive"
            :value="tags"
            :label="`${$t('Tags')}`"
            :items="tagsFromStore"
            :items-translations="tagsTranslations"
            :set-items-translations="setTagsTranslations"
            :update-item-translation="updateTagTranslation"
          />
          <b-form-group label="Short Description">
            <b-input-group class="input-group-merge">
              <b-form-textarea
                v-model="translation.description"
                :disabled="isSubscriptionInactiveOrBasic"
                :placeholder="$t('Short Description')"
                rows="3"
                no-resize
                @blur="patchTranslationActive"
              />
              <b-input-group-append
                v-if="isSubscriptionPremium"
                is-text
              >
                <icon-translate
                  v-b-tooltip.hover.top="$t('Auto Translate')"
                  class="size-18"
                  role="button"
                  @click.native="onClickTranslateField('description')"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group :label="$t('Full Description')">
            <b-input-group class="input-group-merge">
              <b-form-textarea
                v-model="translation.longDescription"
                :disabled="isSubscriptionInactiveOrBasic"
                :placeholder="$t('Full Description')"
                rows="3"
                no-resize
                @blur="patchTranslationActive"
              />
              <b-input-group-append
                v-if="isSubscriptionPremium"
                is-text
              >
                <icon-translate
                  v-b-tooltip.hover.top="$t('Auto Translate')"
                  class="size-18"
                  role="button"
                  @click.native="onClickTranslateField('longDescription')"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-card>
        <b-card-actions
          :title="$t('cardSuggestionsTitle')"
          action-collapse
        >
          <b-form-group
            :label="$t('cardSuggestionsNameLabel')"
            class="mb-0"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="translation.suggestionName"
                :placeholder="$t('cardSuggestionsNamePlaceholder')"
                :disabled="isSubscriptionInactiveOrBasic"
                @blur="patchTranslationActive"
              />
              <b-input-group-append
                v-if="isSubscriptionPremium"
                is-text
              >
                <icon-translate
                  v-b-tooltip.hover.top="$t('Auto Translate')"
                  class="size-18"
                  role="button"
                  @click.native="onClickTranslateField('suggestionName')"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-card-actions>
      </template>
    </translate>
    <template v-else>
      <b-overlay
        :show="isFetching"
        spinner-variant="primary"
      >
        <b-card
          no-body
          class="pt-2"
        >
          <div class="pl-2 pr-2">
            <b-row>
              <b-col>
                <feather-icon
                  icon="InfoIcon"
                  role="button"
                  size="20"
                  class="text-primary d-flex"
                  @click.stop="setIsInfo(['combination', 'main'])"
                />
              </b-col>
              <b-col
                cols="auto"
                class="d-flex"
              >
                <icon-stop-list
                  v-b-tooltip.hover.top="$t('Temporary Unavailable')"
                  :class="['mr-2 size-20', {'fill-danger': !isEnabled}]"
                  role="button"
                  @click.native="onClickEnabled"
                />
                <feather-icon
                  v-b-tooltip.hover.top="$t('Hide in Menus')"
                  class="mr-2"
                  :icon="isVisible ? 'EyeIcon' : 'EyeOffIcon'"
                  role="button"
                  size="20"
                  @click="onClickVisible"
                />
                <icon-preview
                  v-b-tooltip.hover.top="$t('Preview')"
                  role="button"
                  class="size-20 mr-2"
                  :style="{
                    opacity: offers.length ? 1 : 0.5,
                    cursor: offers.length ? 'pointer' : 'default',
                  }"
                  @click.native="openPreviewModal"
                />
                <icon-translate
                  v-b-tooltip.hover.top="$t('Translate')"
                  role="button"
                  class="size-20"
                  @click.native="setTranslateMode(true)"
                />
                <b-spinner
                  v-if="isDuplicating"
                  variant="primary"
                  class="spinner-20 ml-2"
                />
                <feather-icon
                  v-else
                  v-b-tooltip.hover.top="$t('Duplicate')"
                  class="ml-2"
                  icon="CopyIcon"
                  role="button"
                  size="20"
                  @click="onClickDuplicate"
                />
                <feather-icon
                  v-b-tooltip.hover.top="$t('Delete')"
                  class="ml-2 text-danger"
                  icon="TrashIcon"
                  size="20"
                  role="button"
                  @click="isModalConfirmDelete = true"
                />
              </b-col>
            </b-row>
            <b-collapse v-model="isInfo.combination.main">
              <i18n
                path="infoCombinationItem"
                tag="small"
                class="d-block mt-2"
              >
                <template v-slot:slot>
                  <br><br>
                </template>
              </i18n>
            </b-collapse>
            <name-title
              class="mt-2"
              :value="name"
              :is-card-content="isCardContent"
              :placeholder="`${$t('Combination Item Name')}`"
              :failed-rule-required="`${$t('validationErrorRequiredCombinationItemName')}`"
              validation-provider-name="combination_item_name"
              @save="onSaveName"
              @collapse="isCardContent = !isCardContent"
            />
          </div>
          <b-collapse v-model="isCardContent">
            <div class="d-flex flex-wrap border-top">
              <item-badges-tags
                class="w-50"
                icon="AwardIcon"
                :title="`${$t('Badge')}`"
                :tooltip-text="`${$t('badgeNewPlaceholder')}`"
                validation-error-key-items="validationErrorMaxBadges"
                validation-error-key-item="validationErrorMaxBadge"
                :value-max-length="1"
                :text-limit="15"
                :items="badgesFromStore"
                :items-translations="badgesTranslations"
                :set-items="setBadges"
                :set-items-translations="setBadgesTranslations"
                :delete-item="deleteBadge"
                :delete-item-translation="deleteBadgeTranslation"
                :create-item="createBadge"
                :create-item-translation="createBadgeTranslation"
                :value="badges"
                @input="onInputBadges"
              />
              <item-preparation
                v-model="preparation"
                class="w-50"
                @savePreparationTime="onSavePreparationTime"
                @savePreparationIsInMenus="onSavePreparationIsInMenus"
              />
            </div>
            <item-badges-tags
              icon="TagIcon"
              :title="`${$t('Tags')}`"
              :tooltip-text="`${$t('infoTags')}`"
              validation-error-key-items="validationErrorMaxTags"
              validation-error-key-item="validationErrorMaxTag"
              :value-max-length="5"
              :text-limit="25"
              :items="tagsFromStore"
              :items-translations="tagsTranslations"
              :set-items="setTags"
              :set-items-translations="setTagsTranslations"
              :delete-item="deleteTag"
              :delete-item-translation="deleteTagTranslation"
              :create-item="createTag"
              :create-item-translation="createTagTranslation"
              :value="tags"
              @input="onInputTags"
            />
            <item-group :title="`${$t('Description')}`">
              <b-form-group :label="$t('Short Description')">
                <validation-observer ref="voDescription">
                  <validation-provider
                    #default="{ errors }"
                    name="short_description"
                    :rules="`max:${textLimitShort}`"
                  >
                    <textarea-counter
                      size=""
                      :disabled="false"
                      :placeholder="`${$t('Short Description')}`"
                      :is-error="!!errors.length"
                      :text-limit="textLimitShort"
                      :value="description"
                      @input="description = $event"
                      @blur="onBlurDescription('voDescription')"
                    />
                  </validation-provider>
                </validation-observer>
              </b-form-group>
              <b-form-group
                :label="$t('Full Description')"
                class="mb-0"
              >
                <validation-observer ref="voLongDescription">
                  <validation-provider
                    #default="{ errors }"
                    name="full_description"
                    :rules="`max:${textLimitFull}`"
                  >
                    <textarea-counter
                      size=""
                      :disabled="false"
                      :placeholder="`${$t('Full Description')}`"
                      :is-error="!!errors.length"
                      :text-limit="textLimitFull"
                      :value="longDescription"
                      @input="longDescription = $event"
                      @blur="onBlurLongDescription('voLongDescription')"
                    />
                  </validation-provider>
                </validation-observer>
              </b-form-group>
            </item-group>
            <item-videos-images
              class="pb-75"
              page="combination"
              :videos="videos"
              :images="images"
              @input:videos="onInputYoutubeLinks"
              @input:images="onInputImages"
            />
          </b-collapse>
        </b-card>
      </b-overlay>
      <card-pricing
        v-model="offers"
        :is-fetching="isFetching"
        @reset="onResetOffers"
      />
      <b-overlay
        :show="isFetching"
        spinner-variant="primary"
      >
        <b-card-actions
          ref="card-consist"
          class="card-consist"
          :title="$t('combinationConsistTitle')"
          action-collapse
          collapsed
        >
          <template v-slot:after-title>
            <feather-icon
              icon="InfoIcon"
              role="button"
              size="20"
              style="vertical-align: top"
              class="ml-25 text-primary toggle-content"
              @click.stop="setIsInfo(['combination', 'consist'])"
            />
          </template>
          <template v-slot:default>
            <b-collapse v-model="isInfo.combination.consist">
              <small class="d-block">
                {{ $t('infoConsist') }}
              </small>
            </b-collapse>
            <div
              v-for="(item, itemIndex) in items"
              :key="item.id"
              :class="[
                'card-consist__row d-flex pt-50 pb-50',
                itemIndex === 0 ? 'mt-2' : null,
              ]"
            >
              <b-col>
                <b-link
                  :to="{
                    name: item.type === 'MenuItem' ? 'menu-item' : 'combination-menu-item',
                    params: { id: item.id },
                  }"
                >
                  {{ item.name }}
                </b-link>
              </b-col>
              <b-col
                cols="auto"
                class="d-flex align-items-center"
              >
                <feather-icon
                  size="20"
                  role="button"
                  icon="XCircleIcon"
                  class="card-consist__delete"
                  @click="onClickDeleteItem(item.id)"
                />
              </b-col>
            </div>
            <treeselect
              v-model="items"
              v-b-tooltip.hover.top="$t('Select an existing item')"
              class="mt-2"
              :multiple="true"
              :disable-branch-nodes="true"
              :options="optionsTreeselectItems"
              :placeholder="$t('Add Item')"
              :clearable="false"
              value-format="object"
              @close="onCloseTreeselectItems"
            />
          </template>
        </b-card-actions>
      </b-overlay>
      <b-overlay
        :show="isFetching"
        spinner-variant="primary"
      >
        <card-suggestions
          page="combination"
          :name="suggestionName"
          :items="suggestionItems"
          :text-limit="textLimitSuggestions"
          @inputName="suggestionName = $event"
          @inputItems="suggestionItems = $event"
          @saveName="onSaveSuggestionName"
          @saveItems="onSaveSuggestionItems"
        />
      </b-overlay>
    </template>
    <modal-confirm-delete
      v-model="isModalConfirmDelete"
      :title="`${$t('itemDeleteTitle')}`"
      :is-message="false"
      @delete="onDelete"
    />
    <modal-preview
      v-model="isModalPreview"
      :link="previewUrl"
      :title="`${$t('modalPreviewItemTitle')}`"
    />
  </div>
</template>

<script>
import mixinMenuItem from '@/mixins/menu-item'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CombinationMenuItem',
  mixins: [
    mixinMenuItem,
  ],
  methods: {
    async fetch() {
      if (!this.isMenusFetched || !this.isMenuItemsFetched || !this.isBadgesFetched || !this.isTagsFetched) {
        return
      }

      this.isFetching = true

      this.updateHistory()

      const menuItem = this.menuItems.find(i => i.id === this.$route.params.id)
      if (!menuItem) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: this.$t('Not found'),
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        await this.$router.push({ name: 'menu-management' })
        return
      }

      const translations = await this.getTranslations(menuItem.id)
      this.setMainData(menuItem)
      await this.setTranslations(translations)

      if (this.$refs.name && this.$route.params.isFocusOnName) {
        this.$refs.name.focus()
      }

      this.isFetching = false
    },

    async translateAll() {
      const initialRouteName = this.$route.name
      const initialRouteId = this.$route.params.id
      const initialTranslationActive = this.translationActive

      const response = await this.getGoogleTranslation([
        this.name,
        this.description,
        this.longDescription,
        this.suggestionName,
        ...this.badges.map(i => i.name),
        ...this.tags.map(i => i.name),
      ])

      if (
        !response
        || initialRouteName !== this.$route.name
        || initialRouteId !== this.$route.params.id
        || initialTranslationActive !== this.translationActive
      ) {
        return
      }

      let index = 3 // suggestionName index in response

      this.translations = this.translations.map(i => {
        if (i.language === this.translationActive) {
          return {
            ...i,
            name: response[0],
            description: response[1],
            longDescription: response[2],
            suggestionName: response[3],
          }
        }
        return i
      })
      this.patchTranslationActive()

      const refBadges = this.$refs['item-badges-tags-translations_badges']
      refBadges.itemsTranslationsToRender = refBadges.itemsTranslationsToRender.map(i => {
        index += 1
        return { ...i, name: response[index] }
      })
      refBadges.saveItemsTranslations(refBadges.itemsTranslationsToRender)

      const refTags = this.$refs['item-badges-tags-translations_tags']
      refTags.itemsTranslationsToRender = refTags.itemsTranslationsToRender.map(i => {
        index += 1
        return { ...i, name: response[index] }
      })
      refTags.saveItemsTranslations(refTags.itemsTranslationsToRender)
    },
  },
}
</script>

<style lang="sass">
@import '@core/scss/base/bootstrap-extended/_variables.scss'

.card-consist
  &__row
    &:hover
      background-color: $body-bg
      .card-consist__delete
        opacity: 1
  &__delete
    opacity: 0
</style>
