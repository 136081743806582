<template>
  <item-group :title="`${$t('cardYoutubeLinksTitle')} & ${$t('Images')}`">
    <template #after-title="{ isOpened }">
      <feather-icon
        icon="InfoIcon"
        role="button"
        size="20"
        :class="['mr-50 text-primary', isOpened ? null : 'invisible']"
        @click.stop="setIsInfo([page, 'videosImages'])"
      />
    </template>
    <template #default>
      <b-collapse v-model="isInfo[page].videosImages">
        <small class="d-block mb-2">
          {{ $t('cardYoutubeLinksInfo') }}
          <br><br>
          {{ $t('infoImages') }}
          <images-limitation />
        </small>
      </b-collapse>
      <div class="mb-50">
        {{ $t('cardYoutubeLinksTitle') }}
      </div>
      <youtube-links
        :value="videos"
        @input="$emit('input:videos', $event)"
      />
      <div class="mt-2 mb-50">
        {{ $t('Images') }}
      </div>
      <images
        :size="{ width: 800, height: 800 }"
        :is-multiple="true"
        :value="images"
        :update-mutation="UPDATE_MENU_ITEM"
        :update-variables="
          (val) => ({
            updateMenuItemId: this.$route.params.id,
            images: val.map((i) => i.id),
          })
        "
        @input="$emit('input:images', $event)"
      />
    </template>
  </item-group>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'

import ItemGroup from '@/components/ItemGroup.vue'
import YoutubeLinks from '@/components/YoutubeLinks.vue'
import Images from '@/components/Images.vue'
import ImagesLimitation from '@/components/ImagesLimitation.vue'

import UPDATE_MENU_ITEM from '@/gql/mutation/menuItem/updateMenuItem.gql'

export default {
  name: 'ItemVideosImages',
  components: {
    BCollapse,
    ItemGroup,
    YoutubeLinks,
    Images,
    ImagesLimitation,
  },
  props: {
    page: {
      type: String,
      required: true,
      default: '',
    },
    videos: {
      type: Array,
      required: true,
      default: () => [],
    },
    images: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      UPDATE_MENU_ITEM,
    }
  },
  computed: {
    ...mapGetters({
      isInfo: 'main/isInfo',
    }),
  },
  methods: {
    ...mapActions({
      setIsInfo: 'main/setIsInfo',
    }),
  },
}
</script>
