var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('item-group',{attrs:{"title":((_vm.$t('cardYoutubeLinksTitle')) + " & " + (_vm.$t('Images')))},scopedSlots:_vm._u([{key:"after-title",fn:function(ref){
var isOpened = ref.isOpened;
return [_c('feather-icon',{class:['mr-50 text-primary', isOpened ? null : 'invisible'],attrs:{"icon":"InfoIcon","role":"button","size":"20"},on:{"click":function($event){$event.stopPropagation();return _vm.setIsInfo([_vm.page, 'videosImages'])}}})]}},{key:"default",fn:function(){
var this$1 = this;
return [_c('b-collapse',{model:{value:(_vm.isInfo[_vm.page].videosImages),callback:function ($$v) {_vm.$set(_vm.isInfo[_vm.page], "videosImages", $$v)},expression:"isInfo[page].videosImages"}},[_c('small',{staticClass:"d-block mb-2"},[_vm._v(" "+_vm._s(_vm.$t('cardYoutubeLinksInfo'))+" "),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.$t('infoImages'))+" "),_c('images-limitation')],1)]),_c('div',{staticClass:"mb-50"},[_vm._v(" "+_vm._s(_vm.$t('cardYoutubeLinksTitle'))+" ")]),_c('youtube-links',{attrs:{"value":_vm.videos},on:{"input":function($event){return _vm.$emit('input:videos', $event)}}}),_c('div',{staticClass:"mt-2 mb-50"},[_vm._v(" "+_vm._s(_vm.$t('Images'))+" ")]),_c('images',{attrs:{"size":{ width: 800, height: 800 },"is-multiple":true,"value":_vm.images,"update-mutation":_vm.UPDATE_MENU_ITEM,"update-variables":function (val) { return ({
          updateMenuItemId: this$1.$route.params.id,
          images: val.map(function (i) { return i.id; }),
        }); }},on:{"input":function($event){return _vm.$emit('input:images', $event)}}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }