<template>
  <div
    v-if="isEditMode"
    v-click-outside="onClickOutside"
    class="d-flex flex-wrap align-items-start"
  >
    <validation-provider
      #default="{ errors }"
      name="price"
      rules="integer_double"
      :vid="`${offerId}_price`"
      class="d-inline-flex flex-column"
    >
      <b-input-group
        size="sm"
        :class="errors.length > 0 ? 'is-invalid' : null"
      >
        <b-input-group-prepend is-text>
          {{ currencySymbol }}
        </b-input-group-prepend>
        <b-form-input
          style="width: 108px"
          :value="offerData.price"
          placeholder="0.00"
          :state="errors.length ? false : null"
          @input="$emit('updateValue', [offerId, 'price', $event])"
          @blur="$emit('save')"
        />
      </b-input-group>
      <small
        v-if="errors.length"
        class="text-danger"
      >
        {{ $t('Not a number') }}
      </small>
    </validation-provider>
    <span
      class="d-inline-flex line-height-1 ml-25 mr-25 font-medium-4"
      style="padding-top: 6px"
    >
      /
    </span>
    <input-select
      v-model="quantityModel"
      size="sm"
      placeholder="e.g. 300 gm"
      :is-error="isQuantityError"
      :error-message="`${$t('Invalid Quantity')}`"
      :options="quantityOptions"
      :find-by="quantityFindBy"
      @select="onSelectQuantity"
      @focus="onFocusQuantity"
      @blur="onBlurQuantity"
      @keydown:tab="onKeydownTab"
    />
  </div>
  <b-button
    v-else
    class="p-0 border-0 font-weight-normal"
    variant="link"
    @click="onClickEdit"
  >
    <currency-symbol>{{ getFormattedPrice(offerData.price) }}</currency-symbol>
    <template v-if="offerData.measurementUnit !== 'portion'">
      / {{ offerData.amount }} {{ offerData.measurementUnit }}
    </template>
  </b-button>
</template>

<script>
import {
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { ValidationProvider } from 'vee-validate'
import CurrencySymbol from '@/components/CurrencySymbol.vue'
import InputSelect from '@/components/InputSelect.vue'
import { required, integerDouble } from '@validations'
import mixinFormatter from '@/mixins/formatter'
import directiveClickOutside from '@/directives/clickOutside'

export default {
  name: 'Offer',
  directives: {
    clickOutside: directiveClickOutside,
  },
  components: {
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,

    CurrencySymbol,
    InputSelect,

    ValidationProvider,
  },
  mixins: [
    mixinFormatter,
  ],
  props: {
    offerId: {
      type: String,
      required: true,
      default: '',
    },
    offerData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      isEditMode: false,

      quantity: { amount: null, unit: null },
      isQuantityError: false,

      // validation rules
      required,
      integerDouble,
    }
  },
  computed: {
    ...mapGetters({
      currencySymbol: 'restaurant/currencySymbol',
      measurementUnitParams: 'restaurant/measurementUnitParams',
    }),
    measurementUnitsWithTranslations() {
      const translations = {
        portion: this.$t('measurementUnitPortion'),
        items: this.$t('measurementUnitItems'),
      }
      return this.measurementUnitParams.map(i => ({
        value: i,
        translation: translations[i] || i,
      }))
    },
    quantityOptions() {
      const amount = this.quantity.amount || '1'
      return this.measurementUnitsWithTranslations.map(i => ({
        id: i.value,
        translation: i.translation,
        amount: i.value === 'portion' ? null : amount,
        label: i.value === 'portion' ? i.translation : `${amount} ${i.translation}`,
      }))
    },
    quantityModel: {
      get() {
        const measurementUnitWithTranslation = this.measurementUnitsWithTranslations
          .find(i => i.value === this.quantity.unit || i.translation === this.quantity.unit)
        let unit = measurementUnitWithTranslation
          ? measurementUnitWithTranslation.translation
          : this.quantity.unit || ''
        let amount = this.quantity.amount || ''

        if (this.quantity.unit === 'portion') {
          amount = ''
        } else if (unit) {
          unit = ` ${unit}`
        }
        return amount + unit
      },
      set(value) {
        this.isQuantityError = false

        const val = value.trim().toLowerCase()
        if (!val) {
          return
        }

        const matchAmount = val.match(/^\d+\s*\/\s*\d+|^\d*[.]\d+|^\d+/)
        const matchUnit = val.match(/[^\d\s./ ].*$/)
        const quantity = {
          amount: matchAmount ? matchAmount[0] : this.offerData.amount,
          unit: matchUnit ? matchUnit[0] : null,
        }

        if (quantity.unit) {
          const measurementUnitWithTranslation = this.measurementUnitsWithTranslations
            .find(i => i.translation.startsWith(quantity.unit) || i.value.startsWith(quantity.unit))
          if (measurementUnitWithTranslation) {
            quantity.unit = measurementUnitWithTranslation.value
          } else {
            this.isQuantityError = true
          }
        }

        this.quantity = { ...quantity }
      },
    },
  },
  watch: {
    offerData: {
      handler(value) {
        this.quantity = {
          amount: value.amount,
          unit: value.measurementUnit,
        }
      },
      immediate: true,
    },
  },
  methods: {
    onClickEdit() {
      setTimeout(() => {
        this.isEditMode = true
      })
    },
    cancelEditMode() {
      if (this.isQuantityError) {
        return
      }

      this.isEditMode = false
    },
    onClickOutside() {
      this.cancelEditMode()
    },
    onKeydownTab() {
      this.cancelEditMode()
    },
    quantityFindBy(option, search) {
      if (!search) {
        return true
      }

      const matchAmount = search.match(/^\d+\s*\/\s*\d+|^\d*[.]\d+|^\d+/)
      const matchUnit = search.match(/[^\d\s./ ].*$/)

      const amountValid = (matchAmount ? matchAmount[0] : null) === option.amount
      const unitValid = !matchUnit || option.id.startsWith(matchUnit) || option.translation.startsWith(matchUnit)

      return amountValid && unitValid
    },
    onSelectQuantity(value) {
      const { amount, id: unit } = value
      this.quantity = { amount: amount || null, unit: unit || null }
      this.isQuantityError = !unit || (unit !== 'portion' && !amount)
    },
    onFocusQuantity() {
      if (this.isQuantityError) {
        this.quantity = { amount: null, unit: null }
        this.isQuantityError = false
      }
    },
    onBlurQuantity() {
      if (this.isQuantityError) {
        return
      }

      const { amount, unit } = this.quantity
      const isUpdateAmount = (amount && amount !== this.offerData.amount)
      const isUpdateUnit = (unit && unit !== this.offerData.measurementUnit)

      if (isUpdateAmount) {
        this.$emit('updateValue', [this.offerId, 'amount', amount])
        if (isUpdateUnit) {
          this.$nextTick(() => {
            this.$emit('updateValue', [this.offerId, 'measurementUnit', unit])
            this.$emit('save')
          })
        } else {
          this.$emit('save')
        }
      } else if (isUpdateUnit) {
        this.$emit('updateValue', [this.offerId, 'measurementUnit', unit])
        this.$emit('save')
      }
    },
  },
}
</script>
